header {
    color: #fff; /* White text color */
  }
  
/* Base styles for the navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: rgba(10, 25, 47, 0.85); /* Dark semi-transparent background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    z-index: 1000;
  }
  
  .logo {
    font-size: 28px;
    font-weight: bold;
    color: #00dffc; /* Electric blue for the logo */
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .nav-links li {
    margin: 0 10px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #00dffc; /* Electric blue matches the logo */
    font-size: 16px;
    padding: 10px 15px;
    border: 2px solid transparent; /* No border initially */
    border-radius: 5px;
    transition: all 0.3s ease; /* Smooth transition for color and background */
  }
  
  /* Hover effects for navbar links */
  .nav-links a:hover, .nav-links a:focus {
    color: #fff; /* White text on hover/focus */
    background-color: #007bff; /* Bright blue background */
    border-color: #007bff; /* Matching border color */
  }
  
  /* Responsive and additional navbar styling */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
    }
  
    .nav-links {
      flex-direction: column;
      width: 100%;
    }
  
    .nav-links li {
      width: 100%; /* Full width for mobile */
    }
  
    .nav-links a {
      text-align: center;
      padding: 10px; /* Slightly less padding for mobile */
    }
  }
  
  
  .hero {
    position: relative;
    width: 100%;
    height: 650px; /* Maintain the height */
    background-image: url('../assets/hero-image.png');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 /* Hero Text and Button Container */
.hero-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Overlay to ensure text visibility */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 20px;
  }
  
  /* Button Container */
  .button-container {
    display: flex; /* Ensures buttons are in a row */
    justify-content: center; /* Center buttons horizontally */
    align-items: center; /* Center buttons vertically */
    gap: 20px; /* Space between buttons */
  }
  
  .cta-primary, .cta-secondary {
    padding: 15px 30px; /* Button padding */
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s; /* Smooth hover effect transitions */
  }
  
  .cta-primary {
    background-color: #007bff; /* Primary button color */
    color: white;
  }
  
  .cta-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .cta-secondary {
    background-color: transparent;
    border: 2px solid #fff; /* White border */
    color: white;
  }
  
  .cta-secondary:hover {
    background-color: white; /* White background on hover */
    color: #007bff; /* Blue text on hover */
  }
  