.testimonials-section {
    padding: 50px 20px;
    background-color: #120318; /* Dark background to highlight text */
    text-align: center;
  }
  
  .section-title {
    font-size: 28px;
    color: #00ff95; /* Neon green */
    margin-bottom: 30px;
  }
  
  .testimonials-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .testimonial {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #1e1033; /* Slightly lighter dark background */
    border-left: 5px solid #ff2079; /* Neon pink accent */
  }
  
  .testimonial cite {
    display: block;
    margin-top: 10px;
    font-style: normal;
    font-weight: bold;
    color: #00dffc; /* Neon blue */
  }
  